section.orders {
    display: flex;
    flex-direction: column;
}

.orderLists {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

h2.clientName {
    text-align: left;
    font-size: 1.3rem;
    padding: 0 .3em;
    margin: 0;
}

ul.orderList {
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-self: flex-start;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 0.3em;
}

ul.orderList .order {
    margin: .5em;
    min-width: 20em;
}
.toggleButtonInput {
    display: none;
}
label.toggleButton {
    cursor: pointer;
    display: inline-block;
}

