.orderOverview {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.orderOverview.failed{
    border: 2px var(--theme-border-color-panel-error) solid;
}

.orderOverview.hanging{
    border: 2px var(--theme-border-color-panel-warning) solid;
}

.orderOverview .orderDetails {
    display: flex;
    flex-direction: row;
}

.orderOverview header {
    display: flex;
    flex-direction: row-reverse;
    color: var(--theme-color-title);
}

.orderOverview header svg {
    margin-right: 5px;
}


.orderOverview .badge {
    line-height: 1.5;
    margin: 2px;
    padding-bottom: 4px;
}

.orderOverview .badge.green {
    background-color: var(--arctic-green);
}

.orderOverview .badge.purple {
    background-color: var(--orhid-purple);
}

.orderOverview .badge.red {
    background-color: var(--ruby-red);
}

.orderOverview .badge.orange {
    background-color: var(--uc-orange);
}

.orderOverview .badge.grey {
    background-color: var(--shadow-gray);
}

.orderOverview h3 {
    position: absolute;
    align-self: center;
}

.orderOverview footer {
    display: flex;
    flex-direction: row;
    margin: 1em -1.2em -1.2em -1.2em;
    background: var(--theme-background-color-form);
    padding: 0.6em;
}

.orderOverview footer > * {
    padding: 0 1em 0 0;
}

.ShipmentDetailsContainer {
    text-align: left;
    margin-left: 2em;
    float: left;
}

.ShipmentDetailsContainer span {
    display: block;
}

.ShipmentDetailsContainer h4 {
    margin-top: 1em;
}
