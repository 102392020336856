.ordersForClient {
    margin: .5em 0;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: auto;

}

.ordersForClient .orders {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    list-style: none;
    padding: 0;
}

.ordersForClient H2 {
    width: 10em;
    display: inline-block;
    margin: 0;
    text-align: left;
    padding: .3em 1em;
    text-transform: capitalize;
    color: var(--theme-color-title);
}

.orders > li {
    margin: .5em;
    min-width: 22em;
}

ul.optionslist {
    list-style: none;
    padding: 0;
    text-align: left;
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: row;
}
