.shipmentsView {
    flex-wrap: wrap;
}
.shipmentsView > * {
    margin: 0 1em;
}
.shipmentsView .shipmentEvents {
    width: auto;
    max-width: 35em;
}
.shipmentsList {
    flex: 0;
    flex-basis: 20em;
}
.shipmentDetails {
    transition: max-width .5s ease;
    flex: 0;
    flex-basis: 31em;
    padding: 0;
    position: relative;
}

