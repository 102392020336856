.createView {
    min-width: 30rem;
    width: 50vw;
}

.collapsible.open{
    overflow: visible;
    max-height: 100em;
}

.field.text-right {
    text-align: right;
}
