.spinner {
    position: relative;
    display: block;
    text-align: center;
}

.spinner-logo {
    animation: spinner-spin infinite 2s linear;
    width: 100%;
    height: 40px;
}
@keyframes spinner-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
