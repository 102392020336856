.orders {
    display: flex;
    flex-flow: row wrap;
}
.orders > section {
    flex: 1;
    padding: 0 1em 3em 1em;
    max-width: 50em;
    width: 50em;
}
.order {
    display: block;
    text-align: left;
    flex: 0 1;
    width: 20em;
    margin: 0 0 0.5em;
}
