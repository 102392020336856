.orderDesignSubmit-loading {
    opacity: 0.5;
}

.orderDesignSubmit-overlay {
    margin: auto;
    position: absolute;
}

span.fieldComment {
    color: var(--theme-color-field-comment);
    font-size: 75%;
    font-style: italic;
}

.sql {
    background: var(--theme-background-color-block);
    color: var(--theme-color-code-block);
    font-size: 0.95rem;
    border-radius: 0;
    padding: 1em;
    font-family: monospace;
    white-space: pre-wrap;
    display: block;
    margin: 1em 0;
}

