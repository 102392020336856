.consoleActions.viewList > .panel {
    margin: .5em;
    flex: 0;
    min-width: 18em;
}

.viewListDescription {
    font-size: 0.8rem;
    color: var(--theme-color-comment);
}

#client_input {
    max-width: 20rem;
    margin: 1rem auto;
}

.viewList {
    justify-content: flex-start;
}

.viewList .filtered {
    transform: scale(0.6);
    opacity: 0.5;
}
