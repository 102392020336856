.view {
    min-width: 18em;
    width: 50vw;
    text-align: left;
}

.view pre {
    color: var(--theme-color-code);
    background-color: var(--theme-background-color-code);
    overflow-x: auto;
    padding: 1rem;
    line-height: 1.2rem;
}

.viewList {
    justify-content: flex-start;
}

.viewPickerList {
    flex-direction: column;
    overflow: hidden;
    margin: 1em 0 0 0;
}
.viewPickerList.closed {
    max-height: 0;
}
