:root {

    /* Unacast color scheme */
    --deep-sea-blue: hsl(195, 59%, 24%);
    --deep-sea-blue-lightened: #34768f;
    --deep-sea-blue-darkened: hsl(195, 59%, 18%);

    --uc-orange-darkened: hsl(30, 100%, 45%);
    --uc-orange: hsl(30, 100%, 50%);
    --uc-orange-lightened: hsl(30, 100%, 80%);

    --arctic-green: hsl(172, 100%, 35%);
    --arctic-green-lightened: hsl(172, 100%, 45%);

    --orhid-purple: #85017b;
    --ruby-red: #c42240;

    --shadow-gray: hsl(0, 0%, 25%);
    --shadow-gray-lightened: hsl(0, 0%, 50%);
    --silver-grey-darkened: hsl(0, 0%, 65%);
    --silver-grey: hsl(0, 0%, 96%);

    /* THEME - FONTS */
    --theme-font-family-content: "Roboto Slab", sans-serif;
    --theme-font-family-title: "Noto Sans", sans-serif;

    /* THEME - UNITS */
    --section-minwidth: 30rem;
    --section-width: 50vw;

    /* THEME - COLORS */

    /* Basic */
    --theme-background-color-body: var(--silver-grey);
    --theme-color-content: var(--shadow-gray);
    --theme-background-color-error: var(--ruby-red);
    --theme-background-color-warning: var(--uc-orange-lightened);
    --theme-color-error: #ff0000;
    --theme-color-warning: var(--uc-orange-lightened);
    --theme-color-title: var(--deep-sea-blue);
    --theme-color-title-light: var(--silver-grey);
    --theme-color-label: var(--deep-sea-blue);

    /* Comments */
    --theme-color-comment: var(--deep-sea-blue-lightened);
    --theme-color-field-comment: var(--theme-color-comment);

    /* Links */
    --theme-color-link: var(--uc-orange);
    --theme-color-link-disabled: var(--deep-sea-blue-lightened);
    --theme-color-link-visited: var(--uc-orange);
    --theme-color-link-hover: var(--uc-orange-lightened);
    --theme-color-link-active: var(--theme-color-link-hover);

    /* Form */
    --theme-background-color-form: rgba(0,0,0,0.05);

    /* Input */
    --theme-color-input: var(--deep-sea-blue);
    --theme-color-input-placeholder: var(--uc-orange);
    --theme-background-color-input: var(--silver-grey);
    --theme-border-color-input: var(--deep-sea-blue);

    --theme-background-color-input-disabled: var(--silver-grey-darkened);
    --theme-border-color-input-disabled: var(--deep-sea-blue-darkened);
    --theme-color-input-disabled: var(--silver-grey);

    /* Button */
    --theme-background-color-button-hover: rgba(255, 255, 255, 0.8);
    --theme-font-family-button: var(--theme-font-family-title);
    --theme-border-color-button: var(--theme-border-color-input);
    --theme-background-color-button: var(--silver-grey);
    --theme-color-button: var(--theme-color-input);

    --theme-color-button-calltoaction: var(--silver-grey);

    /* Panel */
    --theme-color-panel: var(--shadow-gray);
    --theme-color-panel-disabled: var(--shadow-gray-lightened);
    --theme-background-color-panel: white;
    --theme-border-color-panel: var(--deep-sea-blue-darkened);
    --theme-header-background-color-panel: var(--deep-sea-blue-darkened);
    --theme-color-panel-deleted: var(--uc-orange-darkened);
    --theme-background-color-panel-deleted: rgb(224, 224, 224);
    --theme-background-color-panel-error: var(--ruby-red);
    --theme-background-color-panel-ok: rgba(150,255,100,1);;
    --theme-color-panel-error: var(--silver-grey);
    --theme-border-color-panel-clickable: var(--deep-sea-blue-lightened);
    --theme-background-color-panel-clickable-hover: var(--silver-grey);
    --theme-border-color-panel-warning: var(--uc-orange-darkened);
    --theme-border-color-panel-error: var(--ruby-red);

    --theme-color-box-shadow-panel: rgba(0, 0, 0, 0.1);
    --theme-background-color-panel-title: var(--deep-sea-blue);

    --theme-background-image-panel: url(img/hex_transparent_deep_sea_blue.svg);

    /* Light panel*/
    --theme-background-color-panel-light: var(--silver-grey);
    --theme-border-color-panel-light: var(--silver-grey);
    --theme-color-panel-light: var(--deep-sea-blue);

    /* Code */
    --theme-color-code: var(--deep-sea-blue);
    --theme-background-color-code: var(--silver-grey);
    --theme-color-code-block: var(--deep-sea-blue);
    --theme-background-color-block: white;
}
