.shipmentSpec {
    width: 50vw;
    min-width: 40rem;
    text-align: left;
}

.shipmentSpec > pre {
    padding: 1em;
    color: var(--theme-color-code);
    overflow: scroll;
    background-color: var(--theme-background-color-code);
}
