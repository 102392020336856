.createPage {
    display: flex;
    flex-flow: row wrap;
}
.createPage > section {
    flex: 1;
    padding: 0 1em 3em 1em;
    max-width: 40em;
    width: 40em;
}
