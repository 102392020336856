
.clientSpecific {
    color: var(--arctic-green);
    font-size: 0.7em;
    font-style: italic;
    margin-top: .5em;
}

.clientSpecific a {
    color: var(--arctic-green);
    text-decoration: underline;
}

.viewSummary.compact {
    flex-direction: row;
    padding: .2em .5em;
    justify-content: space-between;
    margin: 0 0 .3em 0;
}

.closeButton {
    cursor: pointer;
    margin-left: .5em;
}
.closeButton:hover {
    color: rgba(255,255,255,0.7);
}
.viewSummaryAndActions {
    display: flex;
    flex-direction: row;
    align-items: baseline;
}
.viewSummaryAndActions .actions >* {
    margin-left: .5em;
}
.viewSummaryAndActions .viewSummary {
    flex: 1;
}
