.shipmentEvents {
    text-align: left;
    margin: 0;
}

.shipmentEvents h4 {
    margin: 0;
}

.startTime, .endTime {
    background: var(--theme-background-color-panel);
    border: 1px solid var(--theme-border-color-panel);
    display: inline-block;
    padding: .5em;
    box-shadow: 3px 3px 0 rgba(0, 0, 0, .2);
}

.shipmentFailed {
    background: var(--theme-background-color-panel);
    border: 1px solid var(--theme-border-color-panel);
    display: inline-block;
    padding: .5em;
    box-shadow: 3px 3px 0 var(--theme-color-box-shadow-panel);
    margin-left: 3em;
}

.shipmentHanging {
    background: var(--theme-background-color-panel);
    border: 1px solid var(--theme-border-color-panel);
    display: inline-block;
    padding: .5em;
    box-shadow: 3px 3px 0 var(--theme-color-box-shadow-panel);
    margin-left: 3em;
}

.failedEvent {
    color: var(--theme-color-error);
}

.timeline {
    margin: 1.5em 0;
}

.timeline .spinner {
    height: 28px;
    position: relative;
    display: inline-block;
    text-align: center;
    width: 10em;
    margin-top: -1em;
}

.ol-timeline {
    border-left: 2px solid black;
    padding: 1em 0px 0.1em;
    margin: 0 0 0 5em;
    display: flex;
    flex-direction: column;
}

.ol-timeline .event {
    position: relative;
    display: inline-block;
    box-shadow: var(--theme-color-box-shadow-panel) 3px 3px 0px;
    min-width: 12em;
    background: var(--theme-background-color-panel);
    border-top: 1px solid var(--theme-border-color-panel);
    border-right: 1px solid var(--theme-border-color-panel);
    border-bottom: 1px solid var(--theme-border-color-panel);
    padding: 0.3em;
    color: var(--theme-color-panel);
}

.ol-timeline .event .eventDetails {
    margin: .5em 0 0 0;
}

.ol-timeline .eventType {
    /*text-shadow: 1px 1px 7px rgba(0, 0, 0, 1);*/
    text-transform: capitalize;
    color: var(--theme-color-title);
}

.ol-timeline .eventTime {
    font-size: 80%;
    position: absolute;
    left: -6em;
    top: 0px;
    text-align: center;
    width: 6em;
    background: var(--theme-border-color-panel);
    color: var(--theme-background-color-panel);
}

.ol-timeline li {
    list-style: none;
    min-height: 51px;
    padding-bottom: 1em;
    display: flex;
    flex-direction: column;
}

.timelineTitle {
    padding: 1em;
    display: inline-block;
}

.dialogTitle {
    padding: 1em;
}

.dialogSection {
    padding: 1em;
    background-color: var(--theme-border-color-panel-light);
    margin: 1em;
    border: 1px solid var(--theme-border-color-panel)
}

.dialogSection dd, .dialogSection dt {
    padding: 0.2em;
}

    .dialogSection code {
    white-space: pre-wrap;
    word-break: keep-all;
    background-color: white;
    padding: 1em;
}
