.queryValidator {
    display: flex;
    align-items: flex-start;
}
.queryValidatorOuter {
    display: flex;
    flex-direction: column;
}
.queryValidator button {
    flex: 0;
    margin: 0 1em 0 0;
}
.queryValidator .panel {
    flex: 1;
}

.queryValidatorOuter code {
    border: 1px solid var(--theme-border-color-panel);
    background-color: var(--theme-background-color-code);
    color: var(--theme-color-code-block);
    overflow-x: auto;
    font-size: .9em;
    padding: 1em;
}
