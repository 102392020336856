.console {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-grow: 1;
}
.consoleView {
    flex-flow: column nowrap;
    display: flex;
    justify-content: flex-start;
}
.console > section {
    padding: 2em;
}

.consoleAside {
    align-self: stretch;
}

.consoleTile {
    text-decoration: none;
    background-color: var(--theme-background-color-panel);
    color: var(--theme-color-content);
    border: 1px solid var(--theme-border-color-panel);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.2rem;
    margin: 1rem;
    outline: none;
    flex: 1;
    flex-basis: 22em;
    min-width: 22em;

    background-image: url(../../img/hex_transparent_silver.svg);
    background-repeat: no-repeat;
    background-size: 100px;
    background-position: calc(100% + 30px) calc(100% + 60px);
    transition: 0.1s all ease-in-out;
}

.consoleTile.bigButton {
    height: 14em;
    background-image: none;
    margin: 0 0 1em 0;
    display: flex;
    justify-content: flex-end;
}


a.consoleTile {
    box-shadow: 2px 2px 0 var(--theme-color-box-shadow-panel);
    display: block;
    flex: 0 1;
    border-color: var(--theme-border-color-panel-clickable);
}

.consoleTile > img {
    width: 100px;
    margin-bottom: 0.5em;
}

a.consoleTile:hover {
    box-shadow: 5px 5px 1px var(--theme-color-box-shadow-panel);
    background-color: var(--theme-background-color-panel-clickable-hover);
}

a.consoleTile:active {
    background-color: var(--theme-background-color-panel-clickable-hover);
    border-color: var(--theme-border-color-panel-clickable);
}

.consoleTile:visited {
    color: var(--theme-color-panel);
}

.consoleActions {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
}

.description {
    color: var(--theme-color-comment);
}

.noteTile {
    min-height: 5em;
    height: 100%;
    margin: 1em 1em 0;
    text-align: left;
    background: none;
    border: none;
    color: var(--theme-color-content);
    font-size: 0.9em;
}

.noteTile .noteTileContent {
    padding: 1em 0;
}

.consoleButtonAndQuickLinks {
    min-width: 22em;
    margin: 1em;
}
