.queryWizard {
    min-width: 30rem;
    width: 80vw;
}
.queryWizard .dataFieldContainer {
    display: block;
    text-align: center;
}
.queryWizard .selectedFieldsContainer:after {
    content: "";
    display: table;
    clear: both;
}
.queryWizard .selectedFieldsContainer {
     width: 70%;
    float: left;
}
.queryWizard .unselectedFieldsContainer {
    max-width: 25%;
    float: left;
    text-align: left;
    margin-left: 10px;
}
.queryWizard .dataField {
    float: left;
    width: 50%;
    padding: 5px;
}

.queryWizard .dataField .field {
    min-height: 11em;
}

.queryWizard .nameAndTypeContainerDiv {
    display: flex;
}

.queryWizard .nameContainerDiv {
    float: left;
    min-width: 50%;
}

.queryWizard .dataFieldCheckbox {
    cursor:pointer;
}

.queryWizard .typeContainerDiv {
    padding-left: 10px;
}
.queryWizard .buttonsContainer {
    width: 70%;
    display:block;
}

.collapsible.open{
    overflow: visible;
    max-height: 100em;
}

.field {
    text-align: left;
}

.field h5 {
    text-align: center;
    color: var(--theme-color-code);
}

.unchecked label {
    color: var(--theme-color-panel-disabled);
}

.fieldComment {
    margin-top: 3px;
    display: block;
    text-align: left;
}

.sql {
    text-align: left;
}

#bqTable_input {
  max-width: 20rem;
  margin: 1rem auto;
}
