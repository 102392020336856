.panel > .panelHeader {
    background-color: var(--theme-background-color-panel-title);
    width: 100%;
    display: flex;
    font-size: .8em;
    justify-content: center;
    align-items: center;
    padding: 0.3em .3em .3em 1em;
}

.panelHeader h2 {
    color: var(--theme-color-title-light);
    display: inline;
    margin: 0;
    text-align: left;
}
.panelHeader nav {
    margin: 0;
}
