.clientDashboard {
    padding: 1em;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.clientInfo {
    flex: 0;
}
.clientDetails {
    display: flex;
    flex-direction: row;
    flex: 1
}

.clientDashboard .orders {
    margin: 1em;
    flex: 5;
    flex-direction: column;
    display: flex;
}
.clientDashboard .orderComponents {
    text-align: left;
    margin: 1em;
    flex: 1;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
}
.orderComponents section {
    margin-bottom: 2em;
}
ul.componentList {
    list-style: none;
    padding: 0;
    text-decoration: underline;
    color: var(--theme-color-link);
    cursor: pointer;
}

.btn.callToAction.addButton {
    background-color: var(--uc-orange);
    color: var(--theme-color-button-calltoaction);
    border-radius: 50%;
    line-height: 0.75em;
    font-size: .7em;
    width: 1em;
    height: 1em;
    padding: 0;
    margin: 0 .5em;
}
