@import "./Theme_light.css";
@import "./Toggle_switch.css";

body {
    font-family: var(--theme-font-family-content);
    font-weight: 300;
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: var(--theme-font-family-title);
    font-weight: bold;
    color: var(--theme-color-title);
    margin: 0 0 0.5em 0;
    line-height: 1em;
    word-wrap: break-word;
}

h1:first-letter,
h2:first-letter,
h3:first-letter,
h4:first-letter,
h5:first-letter {
    text-transform: uppercase;
}

h1 {
    font-size: 3em;
}

h2 {
    font-size: 2em;
}

h3 {
    font-size: 1.5em;
}

h4 {
    font-size: 1.2em;
}

a {
    color: var(--theme-color-link);
}

a:visited {
    color: var(--theme-color-link-visited);
}

a:active {
    color: var(--theme-color-link-active);
}

a:hover {
    color: var(--theme-color-link-hover);
    text-decoration: none;
}

dl {
    margin: 0;
    display: inline-block;
}

dd,
dt {
    display: inline-block;
    float: left;
    margin: 0 1em 0 0;
    font-size: 90%;
    line-height: 1.5em;
    text-align: left;
}

dt::after {
    content: ":";
}

dt {
    color: var(--theme-color-label);
    clear: left;
}

dd {
    break-after: right;
}

html,
body,
#root {
    background-color: var(--theme-background-color-body);
    font-size: 1rem;
    min-height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    box-sizing: border-box;
}

form {
    background: var(--theme-background-color-form);
    padding: 2rem;
    flex: 1 0;
    display: flex;
    flex-flow: column nowrap;
    text-align: left;
    justify-content: flex-start;
}

label {
    padding-right: 1em;
    flex: 1;
    display: inline-block;
    color: var(--theme-color-label);
    line-height: 2em;
}

select {
    display: inline-block;
    flex: 0 0 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") no-repeat;
    background-position-x: 100%;
    background-position-y: 5px;
}

.fieldset {
    border: none;
    padding: 0;
    margin: 0 0 1.5rem 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    flex: 1;
}

.fieldset :last-child {
    margin-bottom: 0;
}
fieldset.subFields {
    background-color: var(--theme-background-color-form);
    padding: 1em;
    font-size: 0.9em;
}

.field {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-bottom: 2rem;
}

.field p {
    padding: 0 2rem;
    font-size: 0.8rem;
}

.field > :last-child {
    flex: 3 1;
}

.field input {
    width: 100%;
}

@media all and (max-width: 600px) {
    .field {
        flex-flow: column
    }
}

select,
input,
textarea {
    box-sizing: border-box;
    font-size: 0.9em;
    color: var(--theme-color-input);
    background-color: var(--theme-background-color-input);
    padding: 0.2rem 1.2rem 0.2rem 0.5rem;
    flex: 1;
    border: 2px solid var(--theme-border-color-input);
    outline: none;
}

select:disabled,
input:disabled {
    background-color: var(--theme-background-color-input-disabled);
    border: 2px solid var(--theme-border-color-input-disabled);
    color: var(--theme-color-input-disabled);
}

input[type="checkbox"], input[type="radio"] {
    width: 2em;
    flex: 0;
    flex-basis: 2em;
}

option {
    background-color: var(--theme-background-color-input);
}

select:active,
select:focus,
input:active,
input:focus {
}

a.btn, button, label.toggleButton,
input[type="submit"] {
    white-space: nowrap;
    outline: none;
    font-family: var(--theme-font-family-button);
    font-size: 1em;
    border: 2px solid var(--theme-border-color-button);
    background-color: var(--theme-background-color-button);
    color: var(--theme-color-button);
    display: inline-block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 6em;
    padding: 0.5em;
    margin: 0.3em;
    line-height: 1.5em;
    max-width: 100%;
    cursor: pointer;
    text-decoration: none;
    border-radius: 0;
}

::placeholder {
    color: var(--theme-color-input-placeholder);
}

a.btn:hover, button:hover, label.toggleButton:hover,
input[type="submit"]:hover {
    background-color: var(--theme-background-color-button-hover);
}

a.btn.callToAction, button.callToAction,
input[type="submit"].callToAction {
    background-color: var(--uc-orange);
    border-color: var(--uc-orange);
    color: var(--theme-color-button-calltoaction)
}

a.btn.callToAction:hover, button.callToAction:hover,
input[type="submit"].callToAction:hover {
    background-color: var(--uc-orange-darkened);
}

a.btn.callToAction:disabled, button:disabled,
input[type="submit"].callToAction:disabled {
    cursor: auto;
    opacity: 0.5;
}

button.danger {
    background-color: var(--theme-background-color-error);
}

button.small {
    font-size: 0.66em;
    padding: 0.33em;
    margin: 0 0.3em;
    line-height: 1.5em;
}

.buttons {
    margin: 1em 0;
    width: 100%;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: baseline;
    display: flex;
}

.buttons :last-child {
    margin-right: 0;
}

.buttons.left {
    display: block;
}

button.plain {
    border: none;
    background: transparent;
    padding: 0;
    margin: 0 0.3em;
}

input:checked + label.toggleButton {
    color: var(--theme-background-color-button);
    background-color: var(--theme-border-color-button);
}

.App {
    text-align: center;
    display: flex;
    flex-flow: column;
    align-content: stretch;
    min-height: 100vh;
}

code {
    font-family: monospace;
    display: inline-block;
    background-color: var(--theme-background-color-code);
    padding: 0.2em;
    font-size: 1.3em;
    line-height: 1em;
    font-style: normal;
    color: var(--theme-color-code);
    word-break: break-all;
    border-radius: 0;
}

.App-header {
    font-family: var(--theme-font-family-title);
    font-size: 0.8em;
    color: var(--deep-sea-blue-lightened);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-size: auto 250%;
    background: white url(img/header-background.jpg) no-repeat;
    background-position-y: 25%;
    padding: 1rem 2rem;
}

.App-title {
    text-decoration: none;
}

.App-title h4 {
    color: var(--deep-sea-blue);
    margin: 0;
}

.App-disclaimer span {
    color: var(--uc-orange-darkened);
    font-weight: bold;
}

.App-footer {
    font-family: var(--theme-font-family-title);
    font-size: 0.8em;
    color: var(--silver-grey-darkened);
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    background-color: var(--deep-sea-blue-darkened);
    padding: 1rem;
    padding-bottom: 3rem;
}

.App-Content {
    background-color: var(--theme-background-color-body);
    flex: 1;
    padding: 0;
    color: var(--theme-color-content);
    align-content: left;
    flex-flow: row wrap;
    display: flex;
    justify-content: space-evenly;
    max-width: 100%;
}

.App-Content > * {
    max-width: 100%;
}

.App-Content > section {
    justify-content: flex-start;
    margin: 2em;
}

.App-Content h2 {
    width: 100%;
}

.App-intro {
    font-size: large;
}

.loggedIn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.notLoggedIn {
    display: flex;
    justify-content: space-around;
    align-content: space-around;
}

.App-logo {
    animation: App-logo-spin infinite 5s linear;
    margin: 0.5rem;
    font-size: 4rem;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.listRow {
    text-decoration: none;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 0.5rem;
    border-bottom: 1px solid var(--deep-sea-blue-darkened);
    min-width: var(--section-minwidth);
    width: var(--section-width);
}

ul.panelList {
    margin: 0;
    padding: 0;
}

.panel, .lightPanel {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.2rem;
    outline: none;

    background-repeat: no-repeat;
    background-size: 100px;
    background-position: calc(100% + 30px) calc(100% + 60px);
    transition: .1s all ease-in-out;
}

.panel.archived,
.panel.deleted,
.panel.inactive {
    background-color: var(--theme-background-color-panel-deleted);
}

.panel.inactive h4 {
    text-decoration: line-through;
}

.panel.ok {
    background-color: var(--theme-background-color-panel-ok);
}
.panel {
    background-color: var(--theme-background-color-panel);
    color: var(--theme-color-panel);
    border: 1px solid var(--theme-border-color-panel);
    background-image: var(--theme-background-image-panel);
    border-radius: 0;
}

.panel.error {
    background-color: var(--theme-background-color-panel-error);
    color: var(--theme-color-panel-error);
}

a.panel {
    box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.4);
    display: block;
    flex: 0 1;
    border-color: var(--theme-border-color-panel-clickable);
    text-decoration: none;
}

a.panel:hover {
    box-shadow: 5px 5px 1px rgba(0, 0, 0, 0.4);
    background-color: var(--theme-background-color-panel-clickable-hover);
}

a.panel:active {
    background-color: var(--theme-background-color-panel-clickable-hover);
    border-color: var(--theme-border-color-panel-clickable);
}

.panel:visited {
    color: var(--theme-color-panel);
}

span.error {
    color: var(--theme-color-error);
}

.lightPanel {
    background-color: var(--theme-background-color-panel-light);
    color: var(--deep-sea-blue);
    border: 1px solid var(--theme-border-color-panel-light);
    background-image: url(img/hex_transparent_arctic_green.svg);
}

.lightPanel.clickable,
.panel.clickable {
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.lightPanel.clickable:hover,
.panel.clickable:hover {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
}

.section-nav * {
    padding: 1em;
}

.clickable {
    cursor: pointer;
    box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.4);
}

.clickable:hover {
    box-shadow: 5px 5px 1px rgba(0, 0, 0, 0.4);
}

.clickable:active {
    background-color: var(--theme-background-color-panel-clickable-hover);
}

.clickable:visited {
    color: var(--theme-color-panel);
}

.collapsible {
    transition: max-height .3s ease-out;
    overflow: hidden;
    height: auto;
}
.collapsible.collapsed {
    max-height: 0;
}

.archivedDetail {
    color: var(--theme-color-panel-deleted);
    font-size: 0.7em;
    font-style: italic;
    margin-top: .5em;
}
