.orderPage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.orderDetails {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 1em;
}
.orderPage .shipmentsView {
    flex-wrap: wrap-reverse;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
}


.firstShipmentLater {
    color: var(--theme-color-warning);
}

.shipmentSummaryDetails {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.shipmentStatusBadge {
    width: 2em;
    height: 2em;
    margin: 0 1em 0 0;
}
.shipmentStatusBadge.failed {
    color: var(--theme-color-error)
}

.shipmentSummary.selected {
    border: 3px solid var(--uc-orange-lightened);
}
