.panel.orderDetailsPanel {
    padding: 0;
}
.orderDetails .filePathList {
    padding-left: 3em;
    width: 100%;
}

.toggleButton.rawSpecButton {
    align-self: flex-start;
    font-size: 0.8em;
    flex: 0;
    margin-top: 2em;
}
.rawSpec {
    max-height: 60em;
}
.rawSpec code {
    border: 1px solid var(--theme-border-color-panel);
    background-color: var(--theme-background-color-code);
    color: var(--theme-color-code-block);
    white-space: pre;
    overflow-x: auto;
    text-align: left;
    font-size: .9em;
    max-width: 60em;
    padding: 1em;
}

