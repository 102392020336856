.dataset {
    min-width: 18em;
    width: 50vw;
    text-align: left;
}

.dataset pre {
    color: var(--theme-color-code);
    background-color: var(--theme-background-color-code);
    overflow-x: auto;
    padding: 1rem;
    line-height: 1.2rem;
}

.datasetList {
    justify-content: flex-start;
}

.scrollableList {
    overflow-y: scroll; 
    max-height: 10em;
}