.switch {
    position: relative;
    display: inline-block;
    height: 34px;
}

label.switch {
    padding-right: 0;
    text-indent: 72px;
    margin-top: 5px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--silver-grey-darkened);
    -webkit-transition: .4s;
    transition: .4s;
    width: 60px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: var(--deep-sea-blue);
}

input:focus + .slider {
    box-shadow: 0 0 1px var(--deep-sea-blue);
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.credentialsSwitch {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 8px;
    margin-top: -25px;
}
