.targetDetailPage {
    min-width: 10em;
    text-align: left;
    padding: 2em;
}

.targetDetailPage pre {
    color: var(--theme-color-code);
    background-color: var(--theme-background-color-code);
    overflow-x: auto;
    padding: 1rem;
    line-height: 1.2rem;
}

.targetDetailPage .targetInfo {
    padding: 1em;
    background-color: var(--theme-background-color-panel);
}

.targetDetailPage .buttons.left {
    display: block;
}

.targetDetailPage dd {
    margin-bottom: 0.5em;
}
