.createDataset {
    min-width: 30rem;
    width: 50vw;
}

.createDataset .fieldComment {
    margin: 0.5rem;
}

#initialMaxLoadTimestamp_input {
    min-width: 3.5rem;
}


