.orderSummaryCompact {
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    flex: 0 0;
    padding: 0;
}
.orderSummaryCompact .content {
    padding: .5em;
}
.orderSummaryCompact .content h4 {
    display: flex;
    float: left;
}
.orderSummaryHeader {
    clear: both;
    background: var(--theme-header-background-color-panel);
    overflow: auto
}
.orderClientName {
    color: var(--theme-color-title-light);
    font-size: 0.9rem;
    margin-top: 0.3em;
    padding: .3em .5em;
    float: left;
}
.orderProduct {
    color: var(--theme-color-title);
    font-size: 0.9em;
    display: block;
    margin: 5px 0px;
}

.orderSummaryCompact .orderName {
    min-width: 100%;
    font-size: 1.2em;
    /*float: left;*/
}

.scheduleDescription {
    font-size: .9em;
    font-style: italic;
}

.inactiveMessage {
    color: var(--theme-color-panel-deleted);
    font-size: 0.7em;
    font-style: italic;
    margin-top: .5em;
}

.orderSummaryCompact .badge {
    line-height: 1.5;
    margin: 5px;
    padding-bottom: 4px;
    float: right;
}

.orderSummaryCompact .badge.green {
    background-color: var(--arctic-green);
}

.orderSummaryCompact .badge.purple {
    background-color: var(--orhid-purple);
}

.orderSummaryCompact .badge.red {
    background-color: var(--ruby-red);
}

.orderSummaryCompact .badge.orange {
    background-color: var(--uc-orange);
}

.orderSummaryCompact .badge.grey {
    background-color: var(--shadow-gray-lightened);
}



