@media only screen and (max-width: 600px) {
    .console {
        flex-wrap: wrap;
    }
    .consoleAside {
        flex: 1;
        margin: 1em;

    }
    .activityTimeline {
        border-style: solid ;
    }
}

.activityTimeline {
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.8em;
    padding-top: 2em;
    flex-grow: 1;

    background: var(--theme-background-color-form);
    border-color: var(--deep-sea-blue-darkened);
    border-width: 1px;
    border-style: none;
    border-left-style: solid;
}

.activityTimeLineEvents {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin: 0.5em 0 0 0;
    padding: 0;
    overflow: hidden;
    min-width: 20em;
}

.activityTimeLineEvent {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    position: relative;
    padding: 1em;
    margin: 0;
    min-width: 20em;
}

.activityTimeLineEvent:last-child {
    border-right: none;
}

.activityTimeLineEventType {
    margin: 0;
}

.byLine {
    font-size: 0.8em;
}

.byLine::before {
    content: "by ";
}

.eventInfo {
    display: flex;
    flex-direction: row;
}

.eventDetails {
    padding: 0 1em;
    text-align: left;
}
.eventLinks {
    padding: .5em 0;

}
.eventLinks * {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.eventTime {
    font-size: 1.1em;
    font-style: italic;
    text-transform: capitalize;
    color: var(--arctic-green);
    margin-bottom: 0.5em;
}

.hexPicture {
    width: 5em;
    height: 5em;
}

.historyButton {
    flex: 0;
    margin-bottom: 2em;
}
