.targets {
    display: flex;
    flex-flow: row wrap;
}
.targets > section {
    flex: 1;
    padding: 0 1em 3em 1em;
    max-width: 40em;
    width: 40em;
}

.createTarget div.fieldset {
    background: var(--theme-background-color-form);
    padding: 2rem;
    flex: 1 0;
    display: flex;
    flex-flow: column nowrap;
    text-align: left;
    justify-content: flex-start;
}

.createTarget label {
    padding-top: 0.2em;
}

.createTarget .fieldset .field h5 {
    padding-top: 0.8em;
    padding-right: 0.2em;
}
.targetsList {
    text-align: left;
}
.target {
    display: block;
    text-align: left;
    margin-bottom: 2em;
    flex: 0;
}

.targetDescription {
    font-size: 0.9em;
    display: inline-block;
    margin: 1em 0 1em 0;
    padding: 1em;
    color: var(--theme-color-field-comment);
    background-color: rgba(0, 0, 0, 0.1);
}

.policy-list-description {
    font-size: 0.9em;
    display: inline-block;
    margin: 0 0 1em 0;
    padding: 1em;
    color: var(--theme-color-field-comment);
    background-color: rgba(0, 0, 0, 0.1);
}

.policy-button-container {
    display: flex;
    justify-content: center;
}

.policy-list {
    list-style: none;
}

.policy-list li {
    margin: 0.5em 0;
}
